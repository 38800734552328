$(document).ready(turnCheckboxesIntoButtons)


function turnCheckboxesIntoButtons() {
  checkboxes = $('.has_many_delete.boolean.input.optional')

  checkboxes.each(function(){ 
    let button = document.createElement("button");
    button.classList = "button as_active_admin_button modified_checkboxes remove_button"
    button.innerHTML = "Х";
    $(this.parentElement.parentElement).prepend(button)
  })

  checkboxes.find("label").hide()
  removeScenarioAction()
}

function removeScenarioAction() {
  $('.modified_checkboxes').click(function(e){
    e.preventDefault()
    $(e.target.parentElement).hide()
    $(e.target.parentElement).find('.has_many_delete.boolean.input.optional > label > input').prop('checked', true);
  })
} 

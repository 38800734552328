$(document).ready(hideNotice)

function hideNotice() {
  let notification = document.querySelector('.flash')
  if (notification) {
    setInterval(function() {
      notification.classList.add('fade');
    }, 3500);
    setInterval(function() {
      notification.remove();
    }, 4000);
  }
}

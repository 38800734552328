// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import '../stylesheets/active_admin.scss';
import '@activeadmin/activeadmin';
import 'activeadmin_addons'
import '@activeadmin-plugins/active_admin_datetimepicker';
import '@fortawesome/fontawesome-free/css/all.css';
import 'arctic_admin';
import '../channels';
import '../controllers';
import '../stylesheets/custom.scss';
import '../stylesheets/spinner.scss';
import '../stylesheets/_typography.scss';
import 'chartkick/chart.js'
window.jQuery = $;
window.$ = $;

require("fixed_drop_menu")
require("bot")
require("clickable_index")
require("hide_notice")
require("main")
require("delete_actions_via_button")
require("russian_datetime_picker")

const BotScenarios = {
  modifyInput: function modifyInput(input){
    let container = input.parentElement.parentElement
    let action_type = input.value
  
    Array.from(container.children).forEach(input => {
      if (!!input.id.match('action_type')) return
  
      let selected = !input.id.match(`${action_type}_input`)
      input.classList.remove("default-hidden");
      input.hidden = selected
      input.disabled = selected
    })
  
    let synced_value;
    let id = container.children[0].id.match(/\d+/)[0]
  
    synced_value = document.getElementById(`bot_scenario_scenario_actions_attributes_${id}_${action_type}`).value
    container.querySelector('textarea.params-input').value = synced_value
  },
  syncValue: function syncValue(input){
    input.parentElement.parentElement.querySelector('textarea.params-input').value = input.value
  }
}

const copyContent = async (el) => {
  var value = el.tagName == 'INPUT' ? el.value : el.innerText
  console.log(el.tagName)
  navigator.clipboard.writeText(value).then(() => {
    let container = el.closest('.click-to-copy-container')
    container.classList.add('copied')

    setTimeout(() => { container.classList.remove('copied'); }, 300); },
    () => { console.error('Failed to copy text'); });
}

window.BotScenarios = BotScenarios

import modifyPhonePaste from '../modify_phone_paste'
window.modifyPhonePaste = modifyPhonePaste
window.copyContent = copyContent


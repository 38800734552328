import { ConfigList } from "@rails/webpacker/package/config_types";

function setTimeOffset() {
  let now = new Date();
  let later = new Date();
  let d1 = new Date();
  let d2 = new Date();
  let set = { 'offset': now.getTimezoneOffset(), 'dst': 0 }
  later.setTime(now.getTime() + 365 * 24 * 60 * 60 * 1000);
  d1.setDate(1);
  d1.setMonth(1);
  d2.setDate(1);
  d2.setMonth(7);

  if ( parseInt( d1.getTimezoneOffset() ) != parseInt( d2.getTimezoneOffset() ) ) { 
  var hemisphere = parseInt( d1.getTimezoneOffset() ) - parseInt( d2.getTimezoneOffset() );

  if (
   ( hemisphere > 0 && parseInt( d1.getTimezoneOffset() ) == parseInt( now.getTimezoneOffset() ) ) ||
   ( hemisphere < 0 && parseInt( d2.getTimezoneOffset() ) == parseInt( now.getTimezoneOffset() ) ) 
  ) {
   set.dst = 1;
  }
 }
  document.cookie ='time_zone='+ JSON.stringify(set) +'; expires='+ later +'; path=/';
  return document.cookie
}

setTimeOffset();

$(document).ready(function() {
  let headers = $('.index_as_table > table > thead > tr > th')
  headers.each(function() {
    let key = this.classList[this.classList.length - 1].replace("col-","")
    let value = $(`.hints-list > li.${key}`).text()
    $(this).attr("hint", value);
  })
    
  headers.hover(function(){
    var title = $(this).attr("hint");
    if (title == "") return
    $(this).data('tipText', title).removeAttr('title');
    $('<p class="tooltip"></p>')
    .text(title)
    .appendTo('body')
    .fadeIn('slow');
  }, function() {
      $('.tooltip').remove();
  }).mousemove(function(e) {
      var mousex = e.pageX - 70; //Get X coordinates
      var mousey = e.pageY - 65; //Get Y coordinates
      $('.tooltip')
      .css({ top: mousey, left: mousex })
  });
});


//batch actions modal form translation fix
$('body').on('modal_dialog:after_open', function() {
  let locale = !!$('#footer_container').text().match('Помощь') ? 'ru' : 'eng'
  $("label:contains('Tag')").text('');

  if (locale == 'eng') return 

  $("button:contains('OK')").text('Применить');
  $("button:contains('Cancel')").text('Назад');
})

//fix select2 inputs for triggering change event on forms
$(document).ready(function() {
  function triggerChange(e) { e.target.closest('form').dispatchEvent(new Event('change')); }

  $('input').on('input', this, triggerChange)
  $('.select2-hidden-accessible, .date-time-picker-input').on('change', this, triggerChange)

  //cancel buttons clears session storage
  $('.cancel').each(function() {
    $(this).attr('data-action', "click->auto-save#clearSessionStorage")
  })
})

function removeEmptyActions(){
  Array.from(document.querySelectorAll('select.action_type_selector')).forEach(select => {
     if (select.selectedIndex == 0) { 
      select.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.remove()
     } 
  }) 
}

function fixNewRecordElement(element){
  if (element.children[0] != undefined && (element.children[0].type == "button" || element.children[0].type == "submit") ) return 

  let button = document.createElement("button");
  button.classList = "button as_active_admin_button"
  button.innerHTML = "Х";
  button.type = "button"
  button.onclick = function() {  this.parentElement.remove() };
  handle_elem = element.querySelector('ol > li.handle')
  if (handle_elem != undefined) { handle_elem.classList = "handle ui-sortable-handle" }
  $(element).prepend(button)
}

$(document).ready(function() {
  let form = document.getElementById('edit_bot_scenario')
  if (form != undefined) { form.onsubmit = removeEmptyActions }

  let form_list = document.getElementsByClassName('has_many_container scenario_actions ui-sortable')[0]
  if (form_list != undefined){
    form_list.addEventListener( 'DOMNodeInserted', function ( event ) {
      if (event.srcElement.type == 'fieldset'){
         fixNewRecordElement(event.srcElement)
      }
    }, false );
  }
})

$(document).ready(makeIndexesClickable)

function clearSelection(){
  if (window.getSelection) {
    if (window.getSelection().empty) {  // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {  // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {  // IE?
    document.selection.empty();
  }
}

function makeIndexesClickable(table){
  action = $('#clickable_index_script').attr('click_to_action')
  $("table.index_table").each(function(){ makeIndexClickable(this, action) })
}

function shiftKeyPressed(rows, clicked_row, last_clicked_row, desired_state){
  if (last_clicked_row == undefined){
    $(clicked_row).find('.col-selectable > div > input').prop( "checked", desired_state )
    return clicked_row
  } else {
    desired_state = last_clicked_row.find('.col-selectable > div > input').prop('checked')
    indexes = [clicked_row.data().index, last_clicked_row.data().index].sort(function(a, b) { return a - b });
    selected_rows = rows.filter(function(){ 
      index = $(this).data().index
      return index >= indexes[0] && index <= indexes[1]
    })

    selected_rows.each(function() { 
      $(this).find('.col-selectable > div > input').prop( "checked", desired_state )
      desired_state ? $(this).addClass('selected') : $(this).removeClass('selected')
     })

    return undefined
  }
}

function hideButtons(table, action){
  $(table).find(`.${action}_link.member_link`).hide()
}

function makeIndexClickable(table, action){
  hideButtons(table, action)
  var last_clicked_row;
  var desired_state;
  let rows = $(table).find('tbody').children()
  $(rows).each(i => $(rows[i]).attr('data-index', i))

  cells = $(table).find('tbody > tr')
  cells.css('cursor', 'pointer')
  cells.each(i => {

    //middle mouse click
    cells[i].addEventListener('mousedown', (e) => {
      if ($(e.target).is('a')) return
      if (e.button == 1) {
        clicked_row = $(e.target).closest('tr')
        window.open(clicked_row.find(`a.${action}_link.member_link`).attr('href'));
        window.focus();
      }
    });

    cells[i].addEventListener("click", function(e) {
      clicked_row = $(e.target).closest('tr')
      checkbox = clicked_row.find('.col-selectable > div > input')
      desired_state = checkbox.prop('checked')
      clearSelection()

      if ($(e.target).is('a')) return
      if (e.shiftKey) return last_clicked_row = shiftKeyPressed(rows, clicked_row, last_clicked_row, desired_state)
      if ($(e.target).is('input[type=checkbox')) {
        desired_state = checkbox.prop('checked')
        last_clicked_row = checkbox.prop('checked') ? clicked_row : undefined
        return
      }
      if ($(e.target).is('div.resource_selection_cell')) {
        checkbox.click()
        desired_state = checkbox.prop('checked')
        last_clicked_row = desired_state ? clicked_row : undefined
        return 
      }
      if ($(e.target).closest('td').hasClass('col-selectable')) return checkbox.toggleClass('checked')
      last_clicked_row = desired_state ? clicked_row : undefined

      if (e.ctrlKey || e.metaKey) return checkbox.prop( "checked", desired_state )
      if (e.target.type == 'checkbox') return
      if (clicked_row.find('a.view_link.member_link, a.edit_link.member_link')[0] == undefined){
        checkbox.prop( "checked", desired_state )
      } else {
        checkbox.click()
        window.location.pathname = clicked_row.find(`a.${action}_link.member_link`).attr('href')
      }
    })
 })
}
import CableReady from 'cable_ready'
import consumer from "./consumer"

CableReady.operations.showReports = operation => {
  if (operation['title'] != undefined) $('#page_title').text(operation['title'])
  $('#active_channel_container').addClass('report-loaded')
  $('#active_channel_container').removeClass('report-hidden')
  $('#main_content >').addClass('report-hidden')
}

CableReady.operations.flashMessage = operation => {
  $('.flashes').append(`<div class='flash flash_${operation['type']}'>${operation['text']}</div>` );
  let notification = $(`.flashes > div:contains("${operation['text']}")`)
  notification.focus();
  setInterval(function() { notification.addClass('fade'); }, 3500);
  setInterval(function() { notification.remove(); }, 4000);
}

consumer.subscriptions.create('PostAnnounceChannel', {
  connected () {
    console.log('post connected')
  },

  received (data) {
    if (data.cableReady) CableReady.perform(data.operations)
  }
})

$(document).ready(makeSettingsInteractive);

function makeSettingsInteractive(){
  $('#settings').hover(function(){ 
    $(this).delay(100).queue(function(){
      $(this).addClass('open');
    });
  }, function(){
    $(this).finish();
  });

  $('#tabs').mouseleave(function(e){ $('#settings').removeClass('open') })
  $('#settings').on('click', function(){ $('#settings').addClass('current open') })
}

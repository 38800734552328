import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form" ]

  connect() {
    this.sessionStorageKey = window.location
    this.setFormData();
  }

  getFormData() {
    const form = new FormData(this.formTarget);
    let data = {}
    for(var [key, value] of form.entries()) {
      if (key == "authenticity_token" || value == "") continue
      if (data[key] == undefined) data[key] = value 
      else if(key.includes('virtual')) {
        let select2_input = $(this.element).find(`[name='${key}']`)
        data[key] = select2_input.select2('data').map((option)=>{ return option.id });
      }
    }

    return data
  }

  saveToSessionStorage() {
    const data = this.getFormData();
    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(data));
  }
  
  setFormData() {
    if(sessionStorage.getItem(this.sessionStorageKey) == null) return
    const data = JSON.parse(sessionStorage.getItem(this.sessionStorageKey));
    const form = this.formTarget;

    setTimeout(()=>{
      for(var [key, input_value] of  Object.entries(data)){
        if (key.includes('virtual')){
          let select_input = $(`[name='${key}']`);
          if (typeof(input_value) == 'string') input_value = [input_value]
          select_input.val(input_value)
          select_input.trigger('change')

          let selected_div = select_input.prev()
          selected_div.empty();
          let model_name = key.split('[')[0]
          let attr_name = key.match(/virtual_(.*)_attr/)[1]
          input_value.forEach((value) => {
            jQuery('<input>', {
              id: `${model_name}_${attr_name}_${value}`,
              name: `${model_name}[${attr_name}][]`,
              type: "hidden",
              value: value
            }).appendTo(selected_div);
          })

        }else if (typeof(input_value) == "string"){
          let input = form.querySelector(`[name='${key}']`);
          if (input.className.includes('select2')) { $(input).val(input_value).trigger('change') }
          else input && (input.value = input_value);
        }
      }
    }, 10)
  }

  clearSessionStorage() {
    if(sessionStorage.getItem(this.sessionStorageKey) != null) {
      sessionStorage.removeItem(this.sessionStorageKey);
    }
  }
}
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form" ]

  previewInputToggle(){
    let input = $(this.element)
    let link_presented = !!input[0]?.value?.match(/http/)
    link_presented ? $('#ban_post_web_page_preview_input').show() : $('#ban_post_web_page_preview_input').hide()
  }

  changeFields(){
    let form = $(this.element)
    let mode = form.find('#ban_post_with_message').select2('data')[0].id
    let tg_container = $('#telegram_announce_container').show()
    let web_page_preview = $('#ban_post_web_page_preview_input')
    let inputs = $(form).find("fieldset.inputs ol li:not('#ban_post_web_page_preview_input')")

    switch (mode) {
      case 'to_all':
        inputs.each(function() {
          this.id == 'ban_post_include_chats_input' ? $(this).hide() : $(this).show()
        })
        tg_container.show()
        break;
      case 'to_specific':
        inputs.each(function() {
          this.id == 'ban_post_exclude_chats_input' ? $(this).hide() : $(this).show()
        })
        tg_container.show()
        break;
      case 'no_message':
        inputs.each(function() {
          this.id == 'ban_post_with_message_input' ? $(this).show() : $(this).hide()
        })
        web_page_preview.hide()
        tg_container.hide()
        break;
    }
  }
}
function modifyPhonePaste(){
  $('input[type=tel]').bind("paste", function(e){
    try {
    e.preventDefault();
    var pastedData = e.originalEvent.clipboardData.getData('text');
    let number = pastedData.match(/\d+/g)
    number = number.join("")
    e.target.value = number;
    } catch(error) { e.target.value = e.originalEvent.clipboardData.getData('text') }
  } );
}

export default modifyPhonePaste